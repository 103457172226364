<template>
  <b-container>
    <b-row align-h="end">
      <b-col cols="12" sm="12" md="6" xl="3" class="mb-2 mb-xl-0">
        <b-card no-body class="bs-br py-3 px-3 my-4">
          <!-- Category -->
          <b-form-group label="Category" label-for="category" label-class="fw-bold">
            <b-form-select v-model="category_id" :options="categories" id="category" class="form-select"
              @change="getPosts()">
              <template #first>
                <b-form-select-option :value="null">All</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-card>
      </b-col>
    </b-row>

    <div class="card-container bs-br" v-if="posts.length > 0">
      <div v-for="(value, key) in posts" :key="key" class="card-item">
        <div class="item-container" @click="viewArticle(value)">
          <b-card>
            <small class="header-card">{{value.post_date_format}}
              <b-badge variant="secondary">{{(value.status) ? 'Published' : 'Draft'}}</b-badge>
            </small>
            <b-img :src="value.image" thumbnail fluid :alt="value.subtitle"></b-img>
            <h4 class="mt-3">
              {{value.title | shortTitle}}
            </h4>
            <div class="footer-card">
              <small>{{`By ${value.user.name} ${value.user.last_name}`}}</small>
              <b-button @click="editPost(value.id)" v-if="checkPermission('Edit All Posts  - Posts') || $session.get('userInfo').agency_id === value.user.agency_id"
                variant="default" class="p-0" style="margin-left: auto;">
                <vue-fontawesome icon="edit" size="1.5" color="#DBDBDB"></vue-fontawesome>
              </b-button>
              <b-button @click="removePost(value.id)" v-if="checkPermission('Delete All Posts - Posts') || $session.get('userInfo').agency_id === value.user.agency_id"
                variant="default" class="p-0">
                <vue-fontawesome icon="trash" size="1.5" color="#DBDBDB"></vue-fontawesome>
              </b-button>
            </div>
            <template #footer>
              <small class="text-muted"><b>Creation date</b> {{value.created_at}}</small>
              <small class="text-muted"><b>Last updated</b> {{value.updated_at}}</small>
              <small class="mt-2">
                <b-badge variant="info">{{value.project.name}}
                  <vue-fontawesome icon="star" v-if="value.featured" style="font-size: 8pt !important; color: #FFDC32"></vue-fontawesome>
                </b-badge>
              </small>
            </template>
          </b-card>
        </div>
      </div>
    </div>
    <div v-else class="bg-white py-3 px-4 bs-br">
      <h5 class="mb-0 fw-bold">No posts found</h5>
    </div>

    <b-modal v-model="detailArticle" hide-footer size="xl" body-class="body_modal_preview_post" header-class="header_modal_preview_post">
      <template #modal-header="{close}">
        <b-button variant="default" @click="close">
          <vue-fontawesome icon="angle-double-left" size="2"></vue-fontawesome>
        </b-button>
        <b-button v-if="checkPermission('Edit All Posts  - Posts') || $session.get('userInfo').agency_id === id_agency"
          pill size="sm" @click="editPost(id_article)">Edit article</b-button>
      </template>
      <b-tabs content-class="mt-3 pt-0 p-3" fill>
        <b-tab title="Preview article view" active>
          <div>
            <b-card class="container_press__column1--card">
              <div class="img-card"><b-img :src="image" fluid></b-img></div>
              <h4>{{ title }}</h4>
              <p>{{ subtitle }}</p>
            </b-card>
          </div>
        </b-tab>
        <b-tab title="Full article view">
          <div class="container_news_room_detail">
            <div class="container_news_room_detail__column_information">
              <b-row>
                  <b-col class="mt-3" cols="12" order-xl="1" order-lg="1" order-md="2" order-sm="2" order="2">
                      <h2 class="text-center mb-5">
                          {{title}}
                      </h2>
                      <h4 class="text-center mb-5">
                          {{subtitle}}
                      </h4>
                  </b-col>
                  <b-col class="mt-3" cols="12" order-xl="2" order-lg="2" order-md="1" order-sm="1" order="1">
                      <b-img-lazy :src="image" fluid center></b-img-lazy>
                  </b-col>
              </b-row>
              <b-row class="mt-lg-3">
                <b-col cols="12">
                  <small style="color: #6c757d;">{{post_date}}</small>
                </b-col>
              </b-row>
              <p v-html="body" class="mt-3 mb-5"></p>
              <hr>
              <div class="container_news_room_detail__column_information--featured_ons">
                  <a :href="value.link" v-for="(value, key) in featured_posts" :key="key" target="_blank">
                    <b-avatar :src="addLogo(value.link)" :text="addAvatar(value.link)"></b-avatar>
                  </a>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>

    <b-overlay :show="load" rounded="sm" no-wrap></b-overlay>
  </b-container>
</template>

<script>

export default {
  name: 'Posts',
  data() {
    return {
      projects: [],
      categories: [],
      category_id: null,
      posts: [],
      load: true,
      edit_post: false,
      remove_post: false,
      detailArticle: false,

      id_article: null,
      title: null,
      subtitle: null,
      image: null,
      post_date: null,
      body: null,
      featured_posts: [],
      id_agency: null
    };
  },
  metaInfo(){
    return {
      title: 'All Posts',
      titleTemplate: '%s | Do Genius On'
    }
  },
  mounted(){
    this.getProjects();
    this.getCategories();
    this.getPosts();
  },
  methods: {
    getProjects(){
      this.$http.get('project', {headers: {"Authorization": "Bearer "+this.$session.get('jwt')}}).then((response) => {
        if (response.status === 200) {
          this.projects = response.body.data;
        }
      }, (error) => {
        console.log('Error', error)
      })
    },
    getCategories(){
      this.$http.get('category', {headers: {"Authorization": "Bearer "+this.$session.get('jwt')}}).then((response) => {
        if (response.status === 200) {
          this.categories = response.body.data;
        }
      }, (error) => {
        console.log('Error', error)
      })
    },
    getPosts(){
      this.$http.get('post', {
        params: {
          category: this.category_id
        },
        headers: {
          Authorization: `Bearer ${this.$session.get('jwt')}`
        }
      }).then((response) => {
        if (response.status === 200) {
          this.posts = response.body.data;
        }
        this.load = false;
      }, (error) => {
        if (error.status == 500) {
          this.$notify({ group: 'app', text: 'An error has occurred, please try again.', type: 'error' });
        }
      })
    },
    editPost(post){
      this.edit_post = true;
      this.$router.push({name: 'posts.edit', params: {post}});
    },
    removePost(id) {
      this.remove_post = true;
      this.$bvModal.msgBoxConfirm('Are you sure you want to remove this post?', {
        title: 'Please Confirm',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Remove',
        cancelTitle: 'Cancel',
        footerClass: 'p-2 bg-white',
        hideHeaderClose: true,
        centered: true
      }).then(value => {
        if (value) {
          this.load = true;
          this.$http.delete('post/'+id, { headers: { Authorization: `Bearer ${this.$session.get('jwt')}` } }).then((response) => {
            if (response.status === 200) {
              this.getPosts();
            }
            this.load = false;
          }, (error) => {
            if (error.status == 500) {
              this.$notify({ group: 'app', text: 'An error has occurred, please try again.', type: 'error' });
            }
          })
        } else {
          this.remove_post = false;
        }
      }).catch(err => {
        console.log('Error modal', err)
      });
    },
    viewArticle(article) {
      if(!this.edit_post && !this.remove_post) {
        this.detailArticle = true;
        this.id_article = article.id;
        this.title = article.title;
        this.subtitle = article.subtitle;
        this.image = article.image;
        this.post_date = article.post_date_format;
        this.body = article.body;
        this.featured_posts = article.featured_posts;
        this.id_agency = article.user.agency_id;
      }
    }
  }
};
</script>
